import React, { useState } from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { faYoutube, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { 
  BackgroundContainer, 
  CustomHeader, 
  ToolsHeader, 
  CustomCheckboxContainer, 
  CustomCheckboxLabel, 
  CustomIcon
} from './HomeStyles';
import './animations.css';

const services = [
  {
    link: "/services/i01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "I01",
    text: "Detail about I01."
  },
  {
    link: "/services/t01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "T01",
    text: "Detail about T01."
  },
  {
    link: "/services/x01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "X01",
    text: "Detail about X01."
  },
  {
    link: "/services/y01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "Y01",
    text: "Detail about Y01."
  }
];

const filterServices = (checkedItems) => {
  const filterKeys = Object.keys(checkedItems).filter(key => checkedItems[key]);
  return services.filter(service => {
    const firstChar = service.title.charAt(0);
    return filterKeys.some(key => {
      switch (key) {
        case 'YouTube':
          return firstChar === 'Y';
        case 'Instagram':
          return firstChar === 'I';
        case 'Tiktok':
          return firstChar === 'T';
        case 'X':
          return firstChar === 'X';
        default:
          return false;
      }
    });
  });
};

const Services = () => {
  const [checkedItems, setCheckedItems] = useState({
    YouTube: true,
    Instagram: true,
    Tiktok: true,
    X: true
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [name]: checked
    });
  };

  const filteredServices = filterServices(checkedItems);

  return (
    <BackgroundContainer>
      <CustomHeader>サービス一覧</CustomHeader>
      <Container>
        <CustomCheckboxContainer>
          {Object.keys(checkedItems).map((item) => (
            <CustomCheckboxLabel key={item} checked={checkedItems[item]}>
              <input
                type="checkbox"
                name={item}
                checked={checkedItems[item]}
                onChange={handleCheckboxChange}
              />
              {item}
              <CustomIcon icon={
                item === 'YouTube' ? faYoutube :
                item === 'Instagram' ? faInstagram :
                item === 'Tiktok' ? faTiktok :
                faTwitter
              } />
            </CustomCheckboxLabel>
          ))}
        </CustomCheckboxContainer>
        <ToolsHeader>ツール一覧</ToolsHeader>
        <Row as={TransitionGroup}>
          {filteredServices.map((service) => (
            <CSSTransition key={service.title} timeout={500} classNames="fade">
              <Col md={6} lg={4} className="mb-4 position-relative">
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Link to={service.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Card className="h-100">
                      <Card.Img variant="top" src={service.imgSrc} />
                      <Card.Body>
                        <Card.Title>{service.title}</Card.Title>
                        <Card.Text>{service.text}</Card.Text>
                        <div className="btn btn-primary">もっと詳しく</div>
                      </Card.Body>
                    </Card>
                  </Link>
                </motion.div>
              </Col>
            </CSSTransition>
          ))}
        </Row>
        <div className="d-flex justify-content-center">
          <Link to="/" className="btn btn-primary mt-4">トップページに戻る</Link>
        </div>
      </Container>
    </BackgroundContainer>
  );
};

export default Services;
