import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import I01 from './services/I01';
import T01 from './services/T01';
import X01 from './services/X01';
import Y01 from './services/Y01';
import Services from './Services'; // サービス一覧ページのインポート

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/i01" element={<I01 />} />
        <Route path="/services/t01" element={<T01 />} />
        <Route path="/services/x01" element={<X01 />} />
        <Route path="/services/y01" element={<Y01 />} />
        <Route path="/services" element={<Services />} /> {/* サービス一覧ページ */}
      </Routes>
    </Router>
  );
}

export default App;
