import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom'; // Link をインポート
import { LearnMoreButton } from '../HomeStyles'; // 追加

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const BackgroundContainer = styled.div`
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #ffffff 0%, #fff4e0 33%, #f8c471 66%, #f39c12 100%);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`;

const ServiceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #000;
`;

const ServiceTitle = styled.h1`
  font-size: 3.5em;
  margin-bottom: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const ServiceText = styled.p`
  font-size: 1.5em;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const ServiceButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #007bff;
  border-radius: 25px;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;

  &:hover {
    background-color: #0056b3;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
`;

// const BackButton = styled(Link)`
//   display: inline-block;
//   padding: 10px 20px;
//   font-size: 1.2em;
//   color: #fff;
//   background-color: #28a745;
//   border-radius: 25px;
//   text-decoration: none;
//   transition: background-color 0.3s ease, box-shadow 0.3s ease;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

//   &:hover {
//     background-color: #218838;
//     box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
//   }
// `;

const ServiceComponent = ({ title, text, useNowLink }) => {
  return (
    <BackgroundContainer>
      <ServiceContent>
        <ServiceTitle>{title}</ServiceTitle>
        <ServiceText>{text}</ServiceText>
        <ServiceButton href={useNowLink} target="_blank" rel="noopener noreferrer">
          今すぐ使う
        </ServiceButton>
        <LearnMoreButton>
          <Link to="/" className="btn btn-primary">トップページに戻る</Link>
        </LearnMoreButton>
      </ServiceContent>
    </BackgroundContainer>
  );
};

export default ServiceComponent;
