// HomeStyles.js

import styled, { keyframes } from 'styled-components';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const BackgroundContainer = styled.div`
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #fff4e0 0%, #f8c471 50%, #f39c12 100%);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`;

export const CustomHeader = styled.h1`
  font-size: 3.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;

  @media (max-width: 768px) {
    font-size: 2.0em;
  }
`;

export const CustomSubHeader = styled.p`
  font-size: 1.7em;
  margin-bottom: 50px;
  color: #333;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 1.1em;
    margin-bottom: 30px;
  }
`;

export const ToolsHeader = styled.h2`
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #000;
  text-align: left;
  padding-left: 15px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.0em;
  }
`;

export const CustomCheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const CustomCheckboxLabel = styled.label`
  margin: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  color: #333;
  background-color: ${props => (props.checked ? '#fff' : '#888')};
  padding: 10px 15px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 5px 10px;
    font-size: 1.1em;
    padding: 8px 12px;
  }
`;

export const CustomIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  font-size: 1.5em;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

export const CustomCard = styled(Card)`
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const CustomCardBody = styled(Card.Body)`
  background-color: #fff;
  padding: 20px;
`;

export const LearnMoreButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  a {
    padding: 10px 20px;
    font-size: 1.2em;
    color: #fff;
    background-color: #007bff;
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    &:hover {
      background-color: #0056b3;
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const MessageBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 90px auto;
`;

export const MessageBox = styled.div`
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 0;
  font-size: 1.2em;
  color: #333;
  text-align: left;
  width: 80%;
  max-width: 600px;

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 10px;
  }
`;

export const FeaturesSection = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px;
  margin: 90px auto;  
  width: 100vw; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden; 

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const FeaturesHeader = styled.h2`
  font-size: 2em;
  margin: 40px 0;
  color: #333;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  text-align: left;
  font-size: 1.2em;
  color: #333;

  span {
    font-size: 1.5em;
    margin-right: 10px;
    color: #ff6347; /* Star color */
  }

  div {
    display: flex;
    flex-direction: column; /* Stacks FeatureTitle and FeatureText vertically */
  }
`;

export const FeatureText = styled.p`
  font-size: 1.2em;
  color: #555;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const FeatureTitle = styled.h3`
  font-size: 1.5em;
  color: #ee7800;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 1em;
  }

  &::before {
    content: '★';
    margin-right: 10px;
    color: #ee7800;
    font-size: 1.2em;
  }
`;

export const GiveawaySection = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  margin: 30px auto;  
  width: 100vw; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden; 

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const GiveawayHeader = styled.h2`
  font-size: 2em;
  margin: 40px 0;
  color: #333;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const GiveawayText = styled.p`
  font-size: 1.2em;
  color: #555;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;
