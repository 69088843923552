import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap'; 
import { faYoutube, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './animations.css';
import { 
  BackgroundContainer, 
  CustomHeader, 
  CustomSubHeader, 
  ToolsHeader, 
  CustomCheckboxContainer, 
  CustomCheckboxLabel, 
  CustomIcon,
  MessageBoxContainer,
  MessageBox,
  FeaturesSection, 
  FeaturesHeader,
  FeatureItem,
  FeatureText,
  FeatureTitle,
  GiveawaySection, 
  GiveawayHeader,
  GiveawayText
} from './HomeStyles';

const services = [
  {
    link: "/services/i01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "I01",
    text: "Detail about I01."
  },
  {
    link: "/services/t01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "T01",
    text: "Detail about T01."
  },
  {
    link: "/services/x01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "X01",
    text: "Detail about X01."
  },
  {
    link: "/services/y01",
    imgSrc: "https://via.placeholder.com/350x200",
    title: "Y01",
    text: "Detail about Y01."
  }
];

const filterServices = (checkedItems) => {
  const filterKeys = Object.keys(checkedItems).filter(key => checkedItems[key]);
  return services.filter(service => {
    const firstChar = service.title.charAt(0);
    return filterKeys.some(key => {
      switch (key) {
        case 'YouTube':
          return firstChar === 'Y';
        case 'Instagram':
          return firstChar === 'I';
        case 'Tiktok':
          return firstChar === 'T';
        case 'X':
          return firstChar === 'X';
        default:
          return false;
      }
    });
  });
};

const Home = () => {
  const [checkedItems, setCheckedItems] = useState({
    YouTube: true,
    Instagram: true,
    Tiktok: true,
    X: true
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [name]: checked
    });
  };

  const filteredServices = filterServices(checkedItems);

  return (
    <BackgroundContainer>
      <CustomHeader>SNSらくらくショップ</CustomHeader>
      <CustomSubHeader>
        SNS運用に特化した自動化、効率化、分析ツール専門店
      </CustomSubHeader>
      <MessageBoxContainer>
        こんなお悩みありませんか？以下の方のために、
        今すぐにSNS運用を加速させるツールをX種類以上開発しました。
        <MessageBox>hoge</MessageBox>
        <MessageBox>hoge</MessageBox>
        <MessageBox>hoge</MessageBox>
      </MessageBoxContainer>
      <FeaturesSection>
  <FeaturesHeader>SNSらくらくショップの特徴</FeaturesHeader>
  <FeatureItem>
    <div>
      <FeatureTitle>ツールを買い切り型でコスパよく</FeatureTitle>
      <FeatureText>SNSらくらくショップのツールは全て買い切り型です。あなたに必要なツールを必要なだけお買い求めください。</FeatureText>
    </div>
  </FeatureItem>
  <FeatureItem>
    <div>
      <FeatureTitle>購入後すぐにツールを使える</FeatureTitle>
      <FeatureText>購入前にSNSコンサルの訴求等は一切行いません。今すぐSNS運用を加速させられます。</FeatureText>
    </div>
  </FeatureItem>
  <FeatureItem>
    <div>
      <FeatureTitle>誰でも使えるようなサポート</FeatureTitle>
      <FeatureText>操作説明は全て動画で準備しております。ITの知識は一切必要ありません。50代後半の主婦も日々使いこなしています。Googleアカウントさえ持っていれば誰でも使えます。</FeatureText>
    </div>
  </FeatureItem>
  <FeatureItem>
    <div>
      <FeatureTitle>全額返金保証で安心して始められる</FeatureTitle>
      <FeatureText>購入後、1日以内であれば全額返金できます。※返金規定がございます。予めご了承ください。</FeatureText>
    </div>
  </FeatureItem>
</FeaturesSection>
      <CustomCheckboxContainer>
        {Object.keys(checkedItems).map((item) => (
          <CustomCheckboxLabel key={item} checked={checkedItems[item]}>
            <input
              type="checkbox"
              name={item}
              checked={checkedItems[item]}
              onChange={handleCheckboxChange}
            />
            {item}
            <CustomIcon icon={
              item === 'YouTube' ? faYoutube :
              item === 'Instagram' ? faInstagram :
              item === 'Tiktok' ? faTiktok :
              faTwitter
            } />
          </CustomCheckboxLabel>
        ))}
      </CustomCheckboxContainer>
      <Container>
        <ToolsHeader>人気のツール</ToolsHeader>
        <Row as={TransitionGroup}>
          {filteredServices.map((service) => (
            <CSSTransition key={service.title} timeout={500} classNames="fade">
              <Col md={6} lg={4} className="mb-4 position-relative">
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Link to={service.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Card className="h-100">
                      <Card.Img variant="top" src={service.imgSrc} />
                      <Card.Body>
                        <Card.Title>{service.title}</Card.Title>
                        <Card.Text>{service.text}</Card.Text>
                        <div className="btn btn-primary">もっと詳しく</div>
                      </Card.Body>
                    </Card>
                  </Link>
                </motion.div>
              </Col>
            </CSSTransition>
          ))}
        </Row>
        <div className="d-flex justify-content-center">
          <Link to="/services" className="btn btn-primary mt-4">ツール一覧を見る</Link>
        </div>
        <GiveawaySection>
          <GiveawayHeader>無料プレゼント配布中</GiveawayHeader>
          <GiveawayText>
            公式LINEに「プレゼント」とコメントくださった方限定で、SNS運用ツール無料配布の案内をお送りいたします。ぜひこの機会にまずは無料でSNS運用を加速させましょう。
          </GiveawayText>
        </GiveawaySection>
      </Container>
    </BackgroundContainer>
  );
};

export default Home;
